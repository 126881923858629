<!-- LoginPage.vue -->
<template>
    <v-container fluid>
      <v-row align="center" justify="center" style="height: 100vh;">
        <v-col>
          <v-card class="login-card">
            <v-card-title class="title-with-line">Woozza<br> Data DashBoard</v-card-title>
  
            <!-- Login Form -->
            <v-form ref="form" @submit.prevent="login">
              <v-text-field v-model="id" label="ID" 
                :rules="[v => !!v || '아이디를 입력하세요.']"
                 outlined></v-text-field>
              <v-text-field v-model="password" label="PASS" 
                type="password" 
                :rules="[v => !!v || '비밀번호를 입력하세요.']"
                outlined>
              </v-text-field>
              <v-btn type="submit" :loading="isLoading" outlined rounded="lg" size="x-large"  color="#4B69B0" class="mt-2 text-h5">LOGIN</v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { mapGetters, mapMutations } from 'vuex';
  export default {
    data() {
      return {
        id: "",
        password: "",
      };
    },
    computed: {
      ...mapGetters(['isLoading']),
    },
    methods: {      
      ...mapMutations(['setLoginUser', 'setShowMsg']),
      async login() {
        // Simulate login logic for demonstration purposes
        const { valid } = await this.$refs.form.validate();
  
        if (valid) {

          try{
            // 패스워드 SHA256 암호화
            const sha256 = require('js-sha256');
            const passwordSha256 =  sha256(this.password);
            
            // 로그인 요청
            const loginUser = await this.$axios.post('/api/user/v1/login', {
              USER_ID: this.id,
              USER_PW: passwordSha256,
            }) || {};
  
            if( loginUser?.data ){
              this.setShowMsg({isVisible:true,msg: `${loginUser.data.USER_NM}님 로그인 되었습니다.`, color: 'success'});
              this.setLoginUser(loginUser.data);
              this.$router.push({ name: 'home' });
            } else {
              this.setShowMsg({isVisible:true,msg: '로그인 실패', color: 'red'});
            }

          } catch(e){
            console.error(e);
            this.setShowMsg({isVisible:true,msg: `[로그인 오류] ${e.response.data.message}`, color: 'red'});
          }

        } else {
          // Handle unsuccessful login (show error message, etc.)
          console.error("로그인정보 입력 이상오류.");
          this.setShowMsg({isVisible:true,msg: '로그인정보 입력 이상오류', color: 'red'});
        }
      },
    },
  };
  </script>
<style scoped>
.login-card {
  max-width: 400px;
  padding: 20px;
  text-align: center;
  margin: auto;
}

.title-with-line {
  font-size: 40px;
  position: relative;
  text-wrap: wrap;
  text-align: left;
  line-height: 1.1em;
  margin-bottom: 20px;
  font-weight: 700;
}

.title-with-line::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 2px solid black; /* Adjust the color and size as needed */
}
</style>
  