<template>
  <v-app >
    <sidebar></sidebar>

    <v-app-bar app>
      <v-toolbar-title>데이터 조회</v-toolbar-title>
      <!-- <v-btn @click="toggleTheme">Toggle Theme</v-btn> -->
      <v-btn variant='tonal' @click="logout">
        {{ userNm }}님 접속중    <v-icon color="secondary">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid class="h-100">
        <slot></slot>
      </v-container>
    </v-main>
    
    <!-- 화면중앙에 vuetify circle 로딩바 -->
    <v-overlay v-model="IS_LOADING" persistent class="align-center justify-center">
      <v-progress-circular
        indeterminate
        size="88"
        width="10"
        color="#68C36B"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script setup>
import Sidebar from './SidebarView.vue';
import { ref, watch, onMounted  } from 'vue';
import { useTheme } from 'vuetify';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const userNm = ref('');
const mini = ref(true);
const theme = useTheme();
const store = useStore();
const router = useRouter();
const IS_LOADING = ref(false);

// const toggleTheme = () => {
//   // Assuming you have a Vuex store module for theme management

//   theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
// };

// Watch for changes in 'mini' and update 'miniVariant'
watch(mini, (newVal) => {
  theme.refs.app.miniVariant = newVal;
});

// store.isloading 의 값이 변경되면 IS_LOADING 값 변경
watch(() => store.state.isLoading, (newVal) => {
  IS_LOADING.value = newVal;
});

// userNm 값이 변경되면 로그인 정보가 없으면 로그인 페이지로 이동
watch(userNm, (newVal) => {
  console.log('#사용자명 =>', newVal);
  if (!newVal) {
    console.log('#사용자명 없음 => 로그인 페이지로 이동');
    store.commit('setLogoutUser');
    router.push({ name: 'login' });
  }
});

// mounted 정의
onMounted (() => {
  //theme.refs.app.miniVariant = mini.value;
  //theme.global.name.value = 'dark';
  // store 의 loginUser 의 user_nm 값 추출
  userNm.value = store.state.loginUser.USER_NM;
  
});

const logout = () => {
  
  if (!confirm('로그아웃 하시겠습니까?')) return;

  store.commit('setLogoutUser');

  // setMessage
  store.commit('setShowMsg', { isVisible: true, msg: '로그아웃 되었습니다.', color: 'success' });

  // redirect to login page
  router.push({ name: 'login' });
};

</script>


<style>
/* Add any custom styles for your dashboard layout */
</style>
