<template>
    <dashboard-layout :mini="mini" @toggle-sidebar="toggleSidebar">
      <v-card class="pa-2 justify-center">
        <v-row dense class="pa-2">
          <v-col cols="12" md="4" >
            <VueDatePicker 
              input-class-name="dp-custom-input" 
              v-model="date" 
              auto-apply
              :dark="true"
              :markers="markers"                
              :enable-time-picker="false"
              :hide-navigation="['time']"
              locale="ko" 
              :format="formatDte"
              />
          </v-col>
          <v-col cols="12" md="7" >
            <!-- <v-btn color="grey darken-1 mr-1">기상자료</v-btn> -->
            <v-select
              dense
              hide-details="auto"
              v-model="selectVal"
              :items="selectOpts"
              :item-title="item => item.text"
              :item-value="item => item.value"              
              label="기상자료 옵션 선택"
              variant="outlined"
            ></v-select>
          </v-col>
          <v-col cols="12" md="1" >
            <v-btn size="x-large" append-icon="mdi-magnify" :loading="isLoading" color="#5D8EF8" @click="changePage(1)">조회</v-btn>
          </v-col>
      </v-row>      
      <v-row dense >
        <v-col cols="12" md="6">
          <div ref="table" style="width:100%"></div>
          <v-pagination
            density="compact"
            show-first-last-page
            v-model="currentPage"
            :length="totalPages"
            :total-visible="10"
            @update:model-value="changePage"
          ></v-pagination>
        </v-col>
        <!-- Image Section -->
        <v-col cols="12" md="6">
          <!-- 네이버 맵 영역 -->
          <div ref="mapRef" style="width:100% ; height: 35vh"></div>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col cols="12" md="12" style="height:50vh">
          <apexchart width="100%" height="100%" type="line"
           :options="{
                      theme: {
                        mode: 'dark',
                        palette: 'palette10',
                      },
                      chart: {
                            type: 'area',
                            animations: {
                                enabled: true,
                                easing: 'linear',
                                dynamicAnimation: {
                                speed: 500,
                                },
                            },
                            zoom: {
                              type: 'x',
                              enabled: true,
                              autoScaleYaxis: true
                            },               
                            toolbar: {
                                show: true,
                                tools: {
                                  zoomin: true,
                                  zoomout: true,                  
                                  download: true,
                                  reset: true,
                                },
                                export: {
                                  csv: {
                                      enabled: true,
                                  },
                                  svg: {
                                      enabled: true,
                                  },
                                  png: {
                                      enabled: true,
                                  },                        
                                },
                                style: {
                                  icons: {
                                      //fillColor: '#1e1e1e', // 툴바 아이콘 색상 변경
                                      //strokeColor: '#FEB019',
                                  },
                                },
                                autoSelected: 'zoom',
                            },
                            background: '#1e1e1e',
                          },
                      colors: ['#68C36B'],
                      title: {
                              text: '기상자료 그래프',
                              align: 'left',
                              style: {
                                  color: '#FFFFFF',
                              },
                          },
                      dataLabels: {
                        enabled: true,              
                        style: {
                            colors: ['#DA2D2D'],                                    
                        },
                        background: {
                            enabled: true,
                            foreColor: '#ffffff',
                            padding: 4,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: '#FEB019',
                            opacity: 0.9,
                            dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45,
                            },
                        },              
                    },
                    stroke: {
                      //curve: 'straight',
                      curve : 'smooth',
                      width: 5,
                    },
                    grid: {
                      //borderColor: '#FEB019',
                    },
                    markers: {
                      size: 0,
                    },
                    tooltip: {
                      enabled: true,
                      theme: 'dark',
                      style: {
                          fontSize: '14px',
                          colors: ['#ffffff'],
                      },
                    },
                    legend: { //
                        show: true,
                        position: 'top',
                        horizontalAlign: 'center',
                        floating: false,
                        //offsetY: -20,
                        //offsetX: -5,
                        labels: {
                          colors: '#ffffff',
                          useSeriesColors: true,
                        },
                      },
                    fill: {
                      type: 'gradient',
                      colors: ['#5D8EF8'],
                      gradient: {
                        //shadeIntensity: 1,
                        inverseColors: false,
                        shade: 'light',
                        type: 'vertical',
                        opacityFrom: 0.5,
                        opacityTo: 0.25,
                        stops: [0, 70, 100]
                      },
                    },
                    xaxis: {
                        //type: 'datetime',              
                        categories: categories,
                        labels: {
                          style: {
                            colors: '#ffffff',
                            fontSize: '12px',
                            fontFamily: undefined,
                            fontWeight: 800,
                            cssClass: 'apexcharts-xaxis-label',
                          },
                        },
                        axisBorder: {
                            show: true,
                            color: '#FEB019',
                        },
                        axisTicks: {
                            show: true,
                            color: '#FEB019',
                        },

                      },            
                      yaxis: {
                          title: {
                              text: selectText,
                              style: {
                                  color: '#ffffff',
                              },
                          },
                          labels: {
                            style: {
                              colors: '#ffffff',
                              fontSize: '12px',
                              fontFamily: undefined,
                              fontWeight: 800,
                              cssClass: 'apexcharts-xaxis-label',
                            },
                          },
                          axisBorder: {
                              //show: true,
                              //color: '#FEB019',
                          },
                          axisTicks: {
                              show: true,
                              color: '#FEB019',
                          },
                      },
                    }" 
           :series="series">
           </apexchart>
        </v-col>
      </v-row>
      </v-card>
    </dashboard-layout>
  </template>
  
  <script>
  import DashboardLayout from "@/components/DashboardLayout.vue";
  //import DataTable from "@/components/DataTable.vue";
  import Tabulator from 'tabulator-tables';
  import { ko } from 'date-fns/locale';
  import { mapGetters, mapMutations } from 'vuex';
  import { parse ,format } from 'date-fns';

  export default {
    components: {
      DashboardLayout,
    },
    data() {
      return {
        menu: false,
        date: new Date(), // 오늘 날짜 초기화
        locale: ko, // 한국어 로케일 적용
        markers :  [{
                    date: new Date(),
                    type: 'dot',
                    color: 'yellow',
                  }],
        tabulator: null, //variable to hold your table
        tableData: [
                      
                    ], //data for table to display
        mini: false, // Set the initial value of mini-variant mode
        selectVal: 'WD',
        selectText: '풍향 (m/s)',
        selectOpts : [{text:'풍향 (deg)',value:'WD'}
                      ,{text:'풍속 (m/s)',value:'WS'}
                      ,{text:'기온 (c)',value:'TA'}
                      ,{text:'습도 (%)',value:'HM'}
                      ,{text:'기압 (hPa)',value:'PA'}
                      ,{text:'강수감지 (0:무강수)',value:'RN_YN'}
                      ,{text:'시간 누적강수량 (mm)',value:'RN_1HR'}
                      ,{text:'일 누적강수량 (mm)',value:'RN_DAY'}
                    ],
        options: {},
        series: [{
          name: '일 누적 강수량 (mm)',
          data: []
        }],
        naverMapRef: null,
        markerRef: null,
        bfMarkerRef: null,
        markerList : {},
        selRow: null,
        currentPage: 1, // 현재 페이지 번호
        totalPages: 0, // 전체 페이지 수, 예시 값
        pageSize: 100, // 페이지 당 항목 수
      };
    },
    computed: {
      ...mapGetters(['isLoading','getLoginUser']),
      formattedDate() {
        // YYYY-MM-DD 형식으로 날짜 포매팅
        return this.date ? this.date.split('-').join('-') : '';
      },      
    },
    mounted() {
      const vm = this; // Vue 인스턴스의 this를 vm 변수에 저장

      //instantiate Tabulator when element is mounted
      this.tabulator = new Tabulator(this.$refs.table, {
        placeholder:"데이터를 조회하세요.",
        layout: 'fitColumns', //fit columns to width of table (optional)
        height: 300, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
        //maxHeight:"50%",
        data: this.tableData, //link data to table
        reactiveData:true, //enable data reactivity        
        columns: [
                  { headerHozAlign:"center" , title: "지점번호", field: "stnId", align:'center', width: 100 },
                  { headerHozAlign:"center" , title: "주소", field: "addr", align:"left" },
                  { headerHozAlign:"center" , title: "값", field: "val" , width: 100 ,align:"right" },
                ], //define table columns
        //footerElement:"<span style='color:red;'>Footer Message</span>", //add footer element        
        pagination: false,
        paginationSize: 30, // 페이지당 행 수        
        paginationSizeSelector:[30, 40, 50 , 100 , true], //add dropdown to page size selector
        //footerElement: "<div id='tabulator-pagination'></div>", //add custom element to footer
        //selectable:true, //make rows selectable
        rowClick:function(e, row){ //trigger an alert message when the row is clicked
          //console.log("->>Row Click=> " , row.getData());
          vm.setSelRow(row.getData());
        },
      });

      setTimeout(() => {
        this.initMap();
      }, 500);

      this.fetch();
    },
    methods: {
      ...mapMutations(['setShowMsg']),
      
      formatDte(date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        const year = date.getFullYear();

        // 2자리로 맞추기
        if (day < 10) {
          day = `0${day}`;
        }
        if (month < 10) {
          month = `0${month}`;
        }

        return `${year}/${month}/${day}`;
      },
      async initMap() {
        this.$nextTick( async () => {
              const { naver } = window;
              this.naverMapRef = await new naver.maps.Map(
                this.$refs.mapRef , {
                center: new naver.maps.LatLng(37.3595704, 127.105399),
                zoom: 10,
                zoomControl: true,
                zoomControlOptions: {position: 'LEFT_CENTER'},
                mapTypeControl: true,
                });

              console.log(":: naver.maps.Map =>", this.naverMapRef );
              // div 태그 의 style 로 background image 를 /assets/icons/Pin.vue 로 지정하도록 설정하기위한 변수선언
              /* const pin = {
                content: `<div style="width: 50px; height: 50px; background-image: url(${require('@/assets/logo.ico').default}); background-size: cover;"></div>`,
                size: new naver.maps.Size(50, 50),
                anchor: new naver.maps.Point(25, 50),
              }; */

              // 마커 표시
              /* this.markerRef = await new naver.maps.Marker({
                position: new naver.maps.LatLng(37.3595704, 127.105399),
                map: this.naverMapRef,
                icon : {
                  content: [
                          `<div">
                            <img width='50px' height='50px' src="${require('@/assets/logo.png')}">
                          </div>`,
                      ].join(''),
                  //size: new naver.maps.Size(50, 50),
                  //origin: new naver.maps.Point(0, 0),
                  anchor: new naver.maps.Point(25, 50),
                },
                // 아이콘 bounce 
                animation: naver.maps.Animation.BOUNCE,
                //animation: naver.maps.Animation.DROP,
              }); 
              
              console.log(":: naver.maps.Marker =>", this.markerRef );
              */
              
                
        });
      },
      // paging 처리 함수
      async changePage(page) {
        this.currentPage = page;
        await this.fetch();
      },
      // 데이터 로드 함수
      async fetch() {
          const pageNo = this.currentPage;
          const numOfRows = this.pageSize;          
          console.log(":: fetch =>", format(this.date, 'yyyyMMdd'), this.selectVal , pageNo, numOfRows);

          this.$store.commit('setIsLoading', true);

          try {
            this.selectText = this.selectOpts.filter((item) => item.value === this.selectVal)[0].text;            

            // this.tabulator.clearData 및 placeholder 설정
            this.tabulator.clearData();

            // 차트초기화
            this.series = [{
              name: '',
              data: []
            }];
            //this.tabulator.setPlaceholder("데이터를 조회중입니다...");
            
            // 데이터 조회 요청
            const res = await this.$axios.get('/api/data/v1/getDataForDay', {
              params: {
                tm: format(this.date, 'yyyyMMdd')+'00',
                to: format(this.date, 'yyyyMMdd')+'24',
                type: this.selectVal,
                pageNo,
                numOfRows,
              },
            });
            console.log(":: fetch =>", res.data);

            this.totalPages = res.data.totalPages;

            // 데이터 조회 결과를 테이블에 바인딩
            this.tabulator.setData(res.data.data);
            this.setShowMsg({isVisible:true,msg: `${res.data.resultMsg}`, color: 'success'});

            // 마커 생성
            const { naver } = window;
            const _data = res.data.data;

            for (const [index, item] of _data.entries()) {
              const _marker = await new naver.maps.Marker({
                position: new naver.maps.LatLng(item.lat, item.lon),
                map: this.naverMapRef,
                icon : {
                  content: [
                          `<div>
                            <img width='35px' height='35px' style='' alt='${item.addr}' src="${require('@/assets/logo.png')}">
                          </div>`,
                      ].join(''),
                  //size: new naver.maps.Size(50, 50),
                  //origin: new naver.maps.Point(0, 0),
                  anchor: new naver.maps.Point(25, 50),
                },
                // 아이콘 bounce 
                //animation: index == 0 ? naver.maps.Animation.BOUNCE : naver.maps.Animation.DROP,
                //animation: naver.maps.Animation.DROP,
              });
              this.markerList[item.stnId] = _marker;    
              await _marker.setAnimation(index == 0 ? naver.maps.Animation.BOUNCE : naver.maps.Animation.DROP);
              
              // 마커 클릭 이벤트
              naver.maps.Event.addListener(_marker, 'click', () => {
                //if( this.bfMarkerRef ) this.bfMarkerRef.setAnimation(null);
                //this.bfMarkerRef = _marker;
                // 마커 바운스
                //_marker.setAnimation(naver.maps.Animation.BOUNCE);
                this.setSelRow(item);
                this.fetchGraphData();
              });
            }

            // 상세조회를 위한 첫번째 행 선택
            if( res.data.data.length > 0 ){
              await this.setSelRow(res.data.data[0]);
              await this.fetchGraphData();
            }
          } catch (e) {
            console.error(e);
            this.setShowMsg({isVisible:true,msg: `[조회 오류] ${e.response.data.resultMsg}`, color: 'red'});
          } finally {
            // 조회완료 로딩바 숨김
            this.$store.commit('setIsLoading', false);
          }
      },
      async setSelRow(rowData) {
        this.selRow = rowData;
        console.log(":: setSelRow =>", rowData.stnId, this.markerList ,this.selRow);
        if( this.selRow ){
          const { naver } = window;
          // 지도도 해당 좌표로 표시
          this.naverMapRef.panTo(new naver.maps.LatLng(this.selRow.lat, this.selRow.lon));
          this.naverMapRef.setCenter(new naver.maps.LatLng(this.selRow.lat, this.selRow.lon));
          //this.naverMapRef.setZoom(17);
          this.naverMapRef.setZoom(9);

          const stnId = this.selRow.stnId;
          const type = this.selRow.type; // WZ 우짜 , AWS 기상관측장비
          if( this.markerList[stnId] ){
            console.log(":: this.bfMarkerRef =>", stnId , this.bfMarkerRef);
            if( this.bfMarkerRef ){
              this.bfMarkerRef.setAnimation(null);
              this.bfMarkerRef.setIcon({
                content: [
                  `<div>
                    <img width='35px' height='35px' style='' alt='${this.selRow.addr}' src="${require('@/assets/logo.png')}">
                  </div>`,
                ].join(''),
              });
            }
            this.markerList[stnId].setAnimation(naver.maps.Animation.BOUNCE);
            this.markerList[stnId].setIcon({
              content: [
                `<div style='width:42px;height:42px;padding:2px'>
                  <img width='38px' height='38px' style='padding:2px;border-radius:15%;background-color:${type=='WZ'?'#0dbc79':'#ed8484'}' alt='${this.selRow.addr}' src="${require('@/assets/logo.png')}">
                </div>`,
              ].join(''),
            });

            this.bfMarkerRef = this.markerList[stnId];            
          }

          // this.selRow 지도 마커 이동
          //this.markerRef.setPosition(new naver.maps.LatLng(this.selRow.lat, this.selRow.lon));

          await this.fetchGraphData();
        }
      },
      async fetchGraphData(){

        try{          
          this.$store.commit('setIsLoading', true);

          // getDataForDayAll 호출
          const res = await this.$axios.get('/api/data/v1/getDataForDayAll', {
            params: {
              tm: format(this.date, 'yyyyMMdd')+'00',
              to: format(this.date, 'yyyyMMdd')+'24',
              stn_id: this.selRow.stnId,
              type: this.selectVal,
            },
          });
          console.log(":: fetchGraphData =>", res.data);
          const _data = res.data.data;
          
          this.categories = _data.map((item) => {
            let _tm = String(item.tm);         
            console.log(":: fetchGraphData =>", _tm , _tm.substring(0,10) );   
            const itemDate = parse( _tm.substring(0,10) , 'yyyyMMddHH', new Date());
            const strDateTime = format(itemDate, 'MM월dd일 HH시');
            return strDateTime;
          });

          // res.data.data.tm 은 yyyymmddhh 형식으로 x축 , res.data.data.val 은 y축 데이터 조회 결과를 차트에 바인딩
          this.series = [{
            name: this.selectText,
            data: _data.map((item) => {
               return item.val
              }),
          }];
          
          this.setShowMsg({isVisible:true,msg: `${res.data.resultMsg}`, color: 'success'});

        }catch(e){
          console.error(e);
          this.setShowMsg({isVisible:true,msg: `[조회 오류] ${e.response.data.resultMsg}`, color: 'red'});

        }finally{
          // 조회완료 로딩바 숨김
          this.$store.commit('setIsLoading', false);
        }
      },
    },
  };
  </script>
<style>
.dp-custom-input {
  margin-top: 1px;
  height: 3.25rem;
  box-shadow: 0 0 4px #FFFFFF;
  color: #999999;

  &:hover {
    border-color: #1976d2;
  }
}
@import '~tabulator-tables/dist/css/tabulator_midnight.css';

/* tabulator custom css 페이징 사이즈 콤보 dark 모드로 */
.tabulator-page-size {
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid #FEB019;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  --box-shadow: 0 0 4px #FEB019;
}
</style>