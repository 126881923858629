<!-- DashboardPage.vue -->
<template>
  <dashboard-layout>
    <v-card class="h-100 pa-2">
      <v-row no-gutters class="justify-start">
        <v-col class="d-flex" style="max-width: 150px;">
            <v-sheet
              border="md"
              class="pa-1 text-center flex-grow-1"
              color="#3C3C3C"
              max-width="150"
            >
              <h3>총 계정</h3>
            </v-sheet>  
        </v-col>
        <v-col class="d-flex" style="max-width: 200px;">
            <v-sheet
              border="md"
              class="pa-1 text-center flex-grow-1"
              color="#141518"
              max-width="200"
            >
              <h3>{{ tableData.length }} 명</h3>
            </v-sheet>  
        </v-col>
      </v-row>
      <hr />
      <!-- Add Vuetify tabs here -->
      <v-row class="pa-2 ">
        <v-col>
          <h3 class="mt-4 mb-4">신규 계정 등록</h3>
          <v-form ref="form" @submit.prevent="validate">
            <v-text-field
              v-model.trim="user_id"
              :disabled="id!=''"
              :rules="idRules"
              label="사용자 아이디 (5자리 이상)"
              required
            ></v-text-field>
            <v-text-field
              v-model="user_nm"
              label="사용자명"
              :rules="nmRules"
              required
            ></v-text-field>
            <v-text-field
            v-model.trim="user_pw"
            :rules="pwRules"
            label="비밀번호 (8자리 이상, 영문, 숫자, 특수문자 포함)"
            type="password"
            required
            ></v-text-field>
            <v-text-field
            v-model.trim="pw_confirm"
            :rules="pwRules"
            label="비밀번호 확인"
            type="password"
            required
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="사용자 Email"              
              :rules="emailRules"
              required
            ></v-text-field>
            <v-text-field
              v-model="phone"
              label="휴대폰 번호"              
              :rules="phoneRules"
              required
            ></v-text-field>
            
            <v-row justify-center>
              <v-col>
                <v-btn
                  class="mt-4"
                  color="error"
                  rounded="lg" 
                  block
                  size="x-large" prepend-icon="mdi-close-box-outline" :loading="isLoading"
                  @click="reset"
                >
                  입력 초기화
                </v-btn>                
              </v-col>
              <v-col>
                <v-btn
                  class="mt-4"
                  color="success"
                  rounded="lg" 
                  block
                  size="x-large" prepend-icon="mdi-account-plus" :loading="isLoading"
                  type="submit"
                >
                  {{ id ? '수정' : '등록' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col>
          <h3 class="mt-4 mb-4">사용자 목록 <v-icon color="blue" @click="fetch" >mdi-refresh</v-icon> </h3>
          <div ref="table" style="width:100%"></div>
        </v-col>
      </v-row>
    </v-card>
    <!-- Modal for Delete Confirmation -->
    <v-dialog v-model="deleteModal" max-width="650">
      <v-card class="custom-modal-card">
        <v-card-title class="text-h6">사용자 삭제 확인</v-card-title>
        <v-card-text class="text-white">
          {{ user_nm+`(${user_id})` }} 해당 사용자를 삭제하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" outlined rounded="lg" size="large" @click="() => {deleteModal = false }" 
            >취소</v-btn
          >
          <v-btn color="success" rounded="lg" size="large" @click="deleteUser(this.user_id)" 
            >삭제</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/components/DashboardLayout.vue";
import { mapGetters, mapMutations } from 'vuex';
import Tabulator from 'tabulator-tables';

export default {
  components: {
    DashboardLayout,
  },
  data() {
    return {
      id : '',
      user_id: '',
      user_pw: '',
      pw_confirm: '',
      user_nm: '',
      email: '',
      phone: '',
      role: 1, // 기본값 : 일반사용자
      idRules: [
        v => !!v || '아이디는 필수입니다.',
        v => (v && v.length > 4) || '5자리 이상 입력하세요.',
      ],
      nmRules: [
        v => !!v || '사용자명은 필수입니다.',
      ],
      // 비밀번호 규칙 : 8자리 이상, 영문, 숫자, 특수문자 포함
      pwRules: [
        v => !!v || '비밀번호는 필수입니다.',
        v => (v && v.length >= 8) || '8자리 이상 입력하세요.',
        v => (v && /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/.test(v)) || '영문, 숫자, 특수문자를 포함하세요.',
        v => (v && v === this.user_pw) || '비밀번호가 일치하지 않습니다.',
      ],
      emailRules: [
        v => !!v || '이메일은 필수입니다.',
        v => /.+@.+\..+/.test(v) || '이메일 형식이 아닙니다.',
      ],
      phoneRules: [
        v => !!v || '휴대폰 번호는 필수입니다.',        
        v => (v && /^[0-9-]*$/.test(v)) || '숫자와 - 만 입력가능합니다.',
        v => (v && /^010/.test(v)) || '휴대폰 번호는 010으로 시작해야 합니다.',
      ],    
      tabulator: null, //variable to hold your table
      tableData: [],
      deleteModal: false,
    };
  },
  computed: {
      ...mapGetters(['isLoading','getLoginUser']),
  },
  mounted() {
    const vm = this; // Vue 인스턴스의 this를 vm 변수에 저장
    this.tabulator = new Tabulator(this.$refs.table, {
        placeholder:"데이터를 조회하세요.",
        layout: 'fitColumns', //fit columns to width of table (optional)
        //height: 300, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
        maxHeight:"90%",
        data: this.tableData, //link data to table
        reactiveData:true, //enable data reactivity        
        columns: [
                  { headerHozAlign:"center" , title: "순번", field: "ID", align:'center', width: 60 },
                  { headerHozAlign:"center" , title: "아이디", field: "USER_ID", align:'center', width: 100 },
                  { headerHozAlign:"center" , title: "이름", field: "USER_NM", align:'center', width: 150 },
                  { headerHozAlign:"center" , title: "Email", field: "EMAIL", align:'center',  },
                  { headerHozAlign:"center" , title: "휴대폰 번호", field: "PHONE", align:'center', width: 120 },
                  { headerHozAlign:"center" , title: "권한", field: "ROLE", align:"center", width: 100 ,
                    formatter:function(cell){
                      let value = cell.getValue();
                        if(value == 0 ){
                            return "관리자";
                        }else{
                            return "일반유저";
                        }
                    }
                  },
                  { headerHozAlign:"center" , title: "삭제", field: "" , align:"center" , width: 60 , headerSort:false
                    , formatter:"buttonCross" // delete button
                    , cellClick:function(e, cell){
                        console.log("-- DEL : ", cell.getRow().getData());
                        //vm.deleteUser(cell.getRow().getData().USER_ID);
                        vm.deleteModal = true;
                      },
                  },
                ], //define table columns
        //footerElement:"<span style='color:red;'>Footer Message</span>", //add footer element        
        pagination: false,
        //paginationSize: 30, // 페이지당 행 수
        //paginationSizeSelector:[30, 40, 50 , 100 , true], //add dropdown to page size selector
        //footerElement: "<div id='tabulator-pagination'></div>", //add custom element to footer
        //selectable:true, //make rows selectable
        rowClick:function(e, row){ //trigger an alert message when the row is clicked
          //console.log("->>Row Click=> " , row.getData());
          vm.setSelRow(row.getData());
        },
    });

    // 데이터 조회
    this.fetch();      

  },
  methods: {
      ...mapMutations(['setShowMsg']),
      async validate () {
        const { valid } = await this.$refs.form.validate()

        console.log('->>validate=>', valid);  

        // user_id 중복체크
        const dupUserChk = await this.tableData.find(x => x.USER_ID === this.user_id);

        //const vm = this; // Vue 인스턴스의 this를 vm 변수에 저장
        // 비밀번호 확인
        if (this.user_pw !== this.pw_confirm){
          
          // 비밀번호 rule 에러 메시지 추가
          //await this.$refs.form.setErrors({user_pw: ['비밀번호가 일치하지 않습니다.']});
          //await this.$refs.form.setErrors({pw_confirm: ['비밀번호가 일치하지 않습니다.']});

          // 비밀번호 포커스
          //await this.$refs.form.$el.querySelector('input[name="user_pw"]').focus();

          this.setShowMsg({isVisible:true,msg:'비밀번호가 일치하지 않습니다.',color:'red'});
          return;

        }else if( !this.id && dupUserChk ){
          this.setShowMsg({isVisible:true,msg:'이미 등록된 아이디입니다.',color:'red'});
          return;

        }else if (valid){
          //console.log('->>submit=>', valid);
          await this.submit()
        }
      },
      reset () {
        this.$refs.form.reset()
        this.id = '';
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      setSelRow(row){
        console.log('->>setSelRow=>', row);
        //form에 데이터 바인딩
        this.id = row.ID;
        this.user_id = row.USER_ID;
        this.user_nm = row.USER_NM;
        this.email = row.EMAIL;
        this.phone = row.PHONE;
        this.role = row.ROLE;        
      },
      // 데이터 로드 함수
      async fetch(isMsg) {
        try{          
          this.$store.commit('setIsLoading', true);
          this.reset();

          // getDataForDayAll 호출
          const res = await this.$axios.get('/api/user/v1/', {
            params: {
            },
          });
          console.log(":: fetch =>", res.data);
          const _data = res.data.data;
          this.tableData = _data;
          
          // 데이터 조회 결과를 테이블에 바인딩
          this.tabulator.setData(_data);
          if(isMsg !== false) this.setShowMsg({isVisible:true,msg:`${res.data.resultMsg}`,color:'success'});

        }catch(e){
          console.error(e);
          this.setShowMsg({isVisible:true,msg: `[조회 오류] ${e.response.data.resultMsg}`, color: 'red'});

        }finally{
          // 조회완료 로딩바 숨김
          this.$store.commit('setIsLoading', false);
        }
      },

      // 데이터 저장 함수
      async submit() {
        
        try{
          this.$store.commit('setIsLoading', true);

          // 데이터 저장
          const sha256 = require('js-sha256');
          const passwordSha256 = await sha256(this.user_pw);

          const params = {
            USER_ID: this.user_id,
            USER_NM: this.user_nm,
            USER_PW: passwordSha256,
            EMAIL: this.email,
            PHONE: this.phone,
            ROLE: this.role,
          };

          const res = this.id != '' ? await this.$axios.put(`/api/user/v1/${this.user_id}`, params) : await this.$axios.post('/api/user/v1/', params);
          console.log(":: submit =>", this.id , res.data);
          this.setShowMsg({isVisible:true,msg:`${res.data.resultMsg}`,color:'success'});

          // 데이터 조회
          await this.fetch(false);

        }catch(e){
          console.error(e);
          this.setShowMsg({isVisible:true,msg: `[저장 오류] ${e.response.data.resultMsg}`, color: 'red'});
        }finally{
          // 저장완료 로딩바 숨김
          this.$store.commit('setIsLoading', false);
          this.reset();
        }
      },

      // 사용자 삭제 함수
      async deleteUser(id) {
        try{
          this.deleteModal = false;
          
          if( !id || id == '' ){
            this.setShowMsg({isVisible:true,msg: '삭제할 사용자를 선택하세요.', color: 'red'});
            return;
          }

          this.$store.commit('setIsLoading', true);

          // 데이터 삭제
          const res = await this.$axios.delete(`/api/user/v1/${id}`);
          console.log(":: deleteUser =>", res.data);
          this.setShowMsg({isVisible:true,msg:`${res.data.resultMsg}`,color:'success'});

          // 데이터 조회
          await this.fetch(false);

        }catch(e){
          console.error(e);
          this.setShowMsg({isVisible:true,msg: `[삭제 오류] ${e.response.data.resultMsg}`, color: 'red'});
        }finally{
          // 삭제완료 로딩바 숨김
          this.$store.commit('setIsLoading', false);
        }
      },
  },
};
</script>

<style>
.custom-modal-card {
  background-color: black;
}

.text-white {
  color: white;
}
@import '~tabulator-tables/dist/css/tabulator_midnight.css';
</style>