// store/index.js
import { createStore } from "vuex";

export default createStore({
  state: {
    users: [],
    loginUser: {},
    darkTheme: true,
    devices: [],
    isLoading: false,
    showMsg:{isVisible:false,color:'success',msg:'완료되었습니다.'},
  },
  mutations: {
    toggleTheme(state) {
        console.log(state);
      state.darkTheme = !state.darkTheme;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setShowMsg(state,msg){
      state.showMsg = msg;
    },
    setLoginUser(state, user) {
      state.loginUser = user;
      console.log('[VUEX] setLoginUser -> ',user);
      // 로그인 사용자 정보를 localStorage에 저장
      localStorage.setItem("woozza-user", JSON.stringify(user));
    },
    setLogoutUser(state) {
      state.loginUser = {};
      // 로그아웃 사용자 정보를 localStorage에서 삭제
      localStorage.removeItem("woozza-user");
    },
    addUser(state, user) {
      state.users.push(user);
    },
    setUserToDelete(state, userId) {
      state.userToDelete = userId;
    },
    deleteUser(state, userId) {
      state.users = state.users.filter((user) => user.id !== userId);
    },
    addDevice(state, device) {
      state.devices.push(device);
    },
    deleteDevice(state, deviceId) {
      state.devices = state.devices.filter((device) => device.id !== deviceId);
    },
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
    getShowMsg: (state) => {
      return state.showMsg;
    },
    getLoginUser: (state) => {
      return state.loginUser;
    },

  },
});
