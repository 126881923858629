<template>
  <v-navigation-drawer  expand-on-hover rail :mini-variant="mini" permanent>
    <div class="mt-2 pa-2" height="100">
      <img :src="TextLogo" alt="Logo" style="width: 100%; height: 100%;">      
    </div>

    <v-divider></v-divider>
    <v-list-item
        v-for="link in links"
        :key="link.to"
        :to="link.to"
        link
      >
      <v-list-item-content class="d-flex align-center">
        <v-list-item-icon class="mr-1">
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
  </v-navigation-drawer>
</template>

<script>
//import Logo from '@/assets/logo.png';
import TextLogo from '@/assets/woozza_white.png';

export default {
  data() {
    return {
      mini: false,
      drawer: null,
      links: [
        { title: '데이터조회', to: '/', icon: 'mdi-chart-areaspline' },
        { title: '사용자관리', to: '/account', icon: 'mdi-account-box-outline' },
        { title: '장치관리', to: '/device-dashboard', icon: 'mdi-cast-connected' },

      ],
    };
  },
  computed: {
    
  },
  setup() {
    return {
      TextLogo,
    };
  },
};
</script>

<style scoped>

.v-list-item:hover, .v-list-item--active {
  background-color: #3E78F7 !important;
  color: white !important;
}
/* Add any custom styles for your sidebar */
</style>
