<!-- DashboardPage.vue -->
<template>
  <dashboard-layout>
    <v-card class="h-100 pa-2">
      <v-row no-gutters class="justify-start">
        <v-col class="d-flex" style="max-width: 150px;">
            <v-sheet
              border="md"
              class="pa-1 text-center flex-grow-1"
              color="#3C3C3C"
              max-width="150"
            >
              <h3>총 장치</h3>
            </v-sheet>  
        </v-col>
        <v-col class="d-flex" style="max-width: 200px;">
            <v-sheet
              border="md"
              class="pa-1 text-center flex-grow-1"
              color="#141518"
              max-width="200"
            >
              <h3>{{ tableData.length }} 대</h3>
            </v-sheet>  
        </v-col>
      </v-row>
      <hr />
      <!-- Add Vuetify tabs here -->
      <v-row class="pa-2 ">
        <v-col>
          <h3 class="mt-4 mb-4">장치 정보 등록</h3>
          <v-form ref="form" @submit.prevent="validate">
            <v-select
              dense
              required
              :rules="deviceRules"  
              v-model="device_type"
              :items="selectOpts"
              :item-title="item => item.text"
              :item-value="item => item.value"              
              label="장치종류"
              variant="outlined"
            ></v-select>
            <v-text-field
              v-model="stn_id"
              :disabled="id != ''"
              :rules="stnRules"
              label="지점번호 (7자리 이하)"
              required
            ></v-text-field>
            <v-text-field
              v-model="addr"              
              readonly
              label="주소"
              :rules="addrRules"
              required
              append-icon="mdi-map-marker-outline"
              @click="() => {openAddrPopup('addr')}"
            ></v-text-field>
            
            <v-row justify-center>
              <v-col>
                <v-btn
                  class="mt-4"
                  color="error"
                  rounded="lg" 
                  block
                  size="x-large" 
                  prepend-icon="mdi-close-box-outline" :loading="isLoading"
                  @click="reset"
                >
                  입력 초기화
                </v-btn>                
              </v-col>
              <v-col>
                <v-btn
                  class="mt-4"
                  color="success"
                  rounded="lg" 
                  block
                  size="x-large" 
                  prepend-icon="mdi-cast-connected" :loading="isLoading"
                  type="submit"
                >
                  {{ id ? '수정' : '등록' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col>
              <div ref="mapRef" style="width:100% ; height: 35vh"></div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <h3 class="mt-4 mb-4">장치 목록 <v-icon color="blue" @click="fetch" >mdi-refresh</v-icon> </h3>
          <div ref="table" style="width:100%"></div>
        </v-col>
      </v-row>
    </v-card>
    <!-- Modal for Delete Confirmation -->
    <v-dialog v-model="deleteModal" max-width="650">
      <v-card class="custom-modal-card">
        <v-card-title class="text-h6">장치 삭제 확인</v-card-title>
        <v-card-text class="text-white">
          {{ `지점번호(${stn_id})-종류(${device_type_nm})` }} 해당 장치를 삭제하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" outlined rounded="lg" size="large" @click="() => {deleteModal = false }" 
            >취소</v-btn
          >
          <v-btn color="success" rounded="lg" size="large" @click="deleteDevice" 
            >삭제</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/components/DashboardLayout.vue";
import { mapGetters, mapMutations } from 'vuex';
import Tabulator from 'tabulator-tables';

export default {
  components: {
    DashboardLayout,
  },
  data() {
    return {
      device_type: 'WD',
      selectText: '풍향 (m/s)',
      selectOpts : [{text:'풍향 (deg)',value:'WD'}
                    ,{text:'풍속 (m/s)',value:'WS'}
                    ,{text:'기온 (c)',value:'TA'}
                    ,{text:'습도 (%)',value:'HM'}
                    ,{text:'기압 (hPa)',value:'PA'}
                    ,{text:'강수감지 (0:무강수)',value:'RN_YN'}
                    ,{text:'시간 누적강수량 (mm)',value:'RN_1HR'}
                    ,{text:'일 누적강수량 (mm)',value:'RN_DAY'}
                  ],
      options: {},
      id: '',
      stn_id : '',
      zonecode : '',
      roadAddress : '',
      detailAddress : '',
      addr: '',
      device_status: '',
      device_type_nm: '',
      lat: '',
      lon: '',      
      naverMapRef: null,
      markerRef: null,
      deviceRules: [
        v => !!v || '장치종류는 필수입니다.',
      ],
      stnRules: [
        v => {
          if (!v) return '지점번호는 필수입니다.'; // 값이 없을 때
          if (!/^[0-9]*$/.test(v)) return '숫자만 입력가능합니다.'; // 숫자만 입력되었는지
          if (v.length > 7) return `7자리 이하로 입력하세요. 현재 입력된 자리수: ${v.length}`; // 7자리 초과 입력 시
          return true; // 모든 조건을 만족하면 유효
        }
      ],
      addrRules: [
        v => !!v || '주소입력은 필수입니다.',
      ],      
      tabulator: null, //variable to hold your table
      tableData: [],
      deleteModal: false,
    };
  },
  computed: {
      ...mapGetters(['isLoading','getLoginUser']),
  },
  mounted() {
    const vm = this; // Vue 인스턴스의 this를 vm 변수에 저장
    this.tabulator = new Tabulator(this.$refs.table, {
        placeholder:"데이터를 조회하세요.",
        layout: 'fitColumns', //fit columns to width of table (optional)
        //height: 300, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
        maxHeight:"90%",
        data: this.tableData, //link data to table
        reactiveData:true, //enable data reactivity        
        columns: [
                  { headerHozAlign:"center" , title: "순번", field: "ID", align:'center', width: 60 },
                  { headerHozAlign:"center" , title: "장치종류", field: "DEVICE_TYPE_NM", align:'center', width: 150 },
                  { headerHozAlign:"center" , title: "주소", field: "ADDR", align:'center',  },
                  { headerHozAlign:"center" , title: "지점번호", field: "STN_ID", align:'center', width: 100  },                  
                  { headerHozAlign:"center" , title: "삭제", field: "" , align:"center" , width: 60 , headerSort:false
                    , formatter:"buttonCross" // delete button
                    , cellClick:function(e, cell){
                        console.log("-- DEL : ", cell.getRow().getData());
                        //vm.deleteDevice(cell.getRow().getData().stn_id);
                        vm.deleteModal = true;
                      },
                  },
                ], //define table columns
        //footerElement:"<span style='color:red;'>Footer Message</span>", //add footer element        
        pagination: false,
        //paginationSize: 30, // 페이지당 행 수
        //paginationSizeSelector:[30, 40, 50 , 100 , true], //add dropdown to page size selector
        //footerElement: "<div id='tabulator-pagination'></div>", //add custom element to footer
        //selectable:true, //make rows selectable
        rowClick:function(e, row){ //trigger an alert message when the row is clicked
          //console.log("->>Row Click=> " , row.getData());
          vm.setSelRow(row.getData());
        },
    });
    
    setTimeout( async () => {

      await this.initMap();

      // 데이터 조회
      await this.fetch();

      }, 300);

  },
  methods: {
      ...mapMutations(['setShowMsg']),
      async initMap() {
        this.$nextTick( async () => {
              const { naver } = window;
              this.naverMapRef = await new naver.maps.Map(
                this.$refs.mapRef , {
                center: new naver.maps.LatLng(37.3595704, 127.105399),
                zoom: 10,
                zoomControl: true,
                zoomControlOptions: {position: 'LEFT_CENTER'},
                mapTypeControl: true,
                });              
        });
      },
      async validate () {
        const { valid } = await this.$refs.form.validate()

        console.log('->>validate=>', valid);  
        if (valid){
          //console.log('->>submit=>', valid);
          await this.submit()
        }
      },
      reset () {
        this.$refs.form.reset()        
        this.id = '';
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      openAddrPopup(id){
        console.log('->>openAddrPopup=>', id);        
        const { daum } = window;
        new daum.Postcode({
          oncomplete: function(data) {
            console.log('->>openAddrPopup oncomplete =>', data);
            if(id == 'addr'){
              this.zonecode = data.zonecode;
              this.roadAddress = data.roadAddress;
              this.detailAddress = data.jibunAddress;
              this.addr = data.address 
                + (data.buildingName ? ' ' + data.buildingName : '')
                + (data.zonecode ? ' (' + data.zonecode +')'  : '')
                ;

              // 지도에 위치 표시 axios /device/v1/ncpGeo?query=서울특별시 강남구 테헤란로 325&output=json 호출
              this.$axios.get('/api/device/v1/ncp/geo', {
                params: {
                  query: data.address,
                  output: 'json',
                },
              }).then(res => {
                console.log('->>ncpGeo=>', res.data);
                const _data = res.data;
                if(_data.status == 'OK'){
                  this.lat = _data.addresses[0]?.y;
                  this.lon = _data.addresses[0]?.x;
                  this.setMarker();
                }
              }).catch(e => {
                console.error(e);
                this.setShowMsg({isVisible:true,msg: `[주소검색 오류] ${e.response.data.resultMsg}`, color: 'red'});
              }).finally(() => {
                // 조회완료 로딩바 숨김
                this.$store.commit('setIsLoading', false);
              })              
              ;

            }
          }.bind(this) ,
        }).open();
      },
      setMarker(){
        console.log('->>setMarker=>', this.lat, this.lon);
        const { naver } = window;

        // 기존 마커 제거        
        if( this.markerRef ) this.markerRef.setMap(null);

        this.naverMapRef.setCenter(new naver.maps.LatLng(this.lat, this.lon));
        this.naverMapRef.setZoom(14);
        this.markerRef =  new window.naver.maps.Marker({
          position: new naver.maps.LatLng(this.lat, this.lon),
          map: this.naverMapRef,
          animation: naver.maps.Animation.BOUNCE,
        });
        this.naverMapRef
      },
      setSelRow(row){
        console.log('->>setSelRow=>', row);

        //form에 데이터 바인딩 nextTick 사용
        this.$nextTick(() => {
          this.id = row.ID;
          this.stn_id = row.STN_ID;
          this.addr = row.ADDR;
          this.device_type = row.DEVICE_TYPE;
          this.device_type_nm = row.DEVICE_TYPE_NM;
          this.device_status = row.DEVICE_STATUS;
          this.lat = row.LAT;
          this.lon = row.LON;
          this.setMarker();
        });
      },
      // 데이터 로드 함수
      async fetch(isMsg) {
        try{          
          this.$store.commit('setIsLoading', true);
          this.reset();

          // getDataForDayAll 호출
          const res = await this.$axios.get('/api/device/v1/', {
            params: {
            },
          });
          console.log(":: fetch =>", res.data);
          const _data = res.data.data;
          this.tableData = _data;
          
          // 데이터 조회 결과를 테이블에 바인딩
          this.tabulator.setData(_data);
          // 테이블 데이터 첫번째 행 선택
          this.tabulator.selectRow(1);

          if(isMsg !== false) this.setShowMsg({isVisible:true,msg:`${res.data.resultMsg}`,color:'success'});

        }catch(e){
          console.error(e);
          this.setShowMsg({isVisible:true,msg: `[조회 오류] ${e.response.data.resultMsg}`, color: 'red'});

        }finally{
          // 조회완료 로딩바 숨김
          this.$store.commit('setIsLoading', false);
        }
      },

      // 데이터 저장 함수
      async submit() {
        
        try{
          this.$store.commit('setIsLoading', true);

          this.device_type_nm = this.selectOpts.filter(item => item.value == this.device_type)[0].text;

          const params = {
            STN_ID: this.stn_id,
            DEVICE_ID : // stn_id 끝 2자리 추출 
                        this.stn_id.length > 2 ? this.stn_id.substr(this.stn_id.length-2,2) : null,
            DEVICE_STATUS: this.device_status || 0,
            ADDR: this.addr,
            LAT: this.lat,
            LON: this.lon,
            DEVICE_TYPE_NM: this.device_type_nm,
            DEVICE_TYPE: this.device_type,
          };

          const res = this.id != '' ? await this.$axios.put(`/api/device/v1/${this.id}`, params) : await this.$axios.post('/api/device/v1/', params);
          console.log(":: submit =>", this.id , res.data);
          this.setShowMsg({isVisible:true,msg:`${res.data.resultMsg}`,color:'success'});

          // 데이터 조회
          await this.fetch(false);

        }catch(e){
          console.error(e);
          this.setShowMsg({isVisible:true,msg: `[저장 오류] ${e.response.data.resultMsg}`, color: 'red'});
        }finally{
          // 저장완료 로딩바 숨김
          this.$store.commit('setIsLoading', false);
          //this.reset();
        }
      },

      // 장치 삭제 함수
      async deleteDevice() {
        try{
          this.deleteModal = false;
          
          const id = this.id;

          if( !id || id == '' ){
            this.setShowMsg({isVisible:true,msg: '삭제할 장치를 선택하세요.', color: 'red'});
            return;
          }

          this.$store.commit('setIsLoading', true);

          // 데이터 삭제
          const res = await this.$axios.delete(`/api/device/v1/${id}`);
          console.log(":: deleteDevice =>", res.data);
          this.setShowMsg({isVisible:true,msg:`${res.data.resultMsg}`,color:'success'});

          // 데이터 조회
          await this.fetch(false);

        }catch(e){
          console.error(e);
          this.setShowMsg({isVisible:true,msg: `[삭제 오류] ${e.response.data.resultMsg}`, color: 'red'});
        }finally{
          // 삭제완료 로딩바 숨김
          this.$store.commit('setIsLoading', false);
        }
      },
  },
};
</script>

<style>
.custom-modal-card {
  background-color: black;
}

.text-white {
  color: white;
}
@import '~tabulator-tables/dist/css/tabulator_midnight.css';
</style>