import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axiosInstance from './plugins/axios'; // Axios 플러그인
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'

import VueApexCharts from "vue3-apexcharts";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()

const app = createApp(App)
app.config.globalProperties.$axios = axiosInstance; // Vue 인스턴스에 Axios 추가
app.component('VueDatePicker', VueDatePicker);

app.use(store)
  .use(router)
  .use(vuetify)
  .use(VueApexCharts)
  .mount('#app')

