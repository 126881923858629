<template>
  <div id="app">
    <!-- Use the Dashboard component as the main layout -->
    <!-- <Dashboard></Dashboard> -->
    <v-app>
      <router-view></router-view>
      <v-snackbar
        
        :timeout="10000"
        v-model="getShowMsg.isVisible"
        :color="getShowMsg.color"      
        @click="getShowMsg.isVisible = false"
        :class="{ shake: getShowMsg.isVisible }"
      >
        <!-- <template v-slot:activator="{ props }">
          <v-btn color="primary" variant="tonal" class="ma-2" v-bind="props">open</v-btn>
        </template> -->

        <strong style="font-color:#212121;text-align: center;display: block;">{{getShowMsg.msg}}</strong>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
// import Dashboard from "@/components/DashboardLayout.vue";
// mapGetters
import { mapGetters } from 'vuex';

export default {
  components: {
    // Dashboard,
  },
  computed: {
    ...mapGetters(['getShowMsg']),
  },
  methods: {
    
  },
};
</script>

<style scoped>

@keyframes shake {
  0% { transform: translate(0.5px, 0.5px) rotate(0deg); }
  10% { transform: translate(-0.5px, -1px) rotate(-1deg); }
  20% { transform: translate(-1.5px, 0px) rotate(1deg); }
  30% { transform: translate(1.5px, 1px) rotate(0deg); }
  40% { transform: translate(0.5px, -0.5px) rotate(1deg); }
  50% { transform: translate(-0.5px, 1px) rotate(-1deg); }
  60% { transform: translate(-1.5px, 0.5px) rotate(0deg); }
  /* 70% { transform: translate(1.5px, 0.5px) rotate(-1deg); }
  80% { transform: translate(-0.5px, -0.5px) rotate(1deg); }
  90% { transform: translate(0.5px, 1px) rotate(0deg); }
  100% { transform: translate(0.5px, -1px) rotate(-1deg); } */
}

.shake {
  animation: shake 0.7s; 
  /* animation-iteration-count: infinite; */
   animation-iteration-count: 1;
}
</style>
