// router.js
import { createRouter, createWebHistory } from 'vue-router'
//import DashboardPage from '../views/DashboardPage.vue'
import AccountPage from '../views/AccountPage.vue'
import LoginPage from '../views/LoginPage.vue' // Import the LoginPage component
import DeviceDashboard from '../views/DeviceDashbaord.vue' // Import the LoginPage component
import DataDashboard from '../views/DataDashboard.vue' // Import the LoginPage component

function isLoggedIn() {
  // 로컬 스토리지에 'user' 항목이 있는지 확인
  return !!localStorage.getItem('woozza-user');
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: DataDashboard,
    // 메인 페이지에 접근 전 로그인 상태 확인
    beforeEnter: (to, from, next) => {
      if (!isLoggedIn()) {
        // 로그인 상태가 아니면 로그인 페이지로 리다이렉트
        next({ name: 'login' });
      } else {
        // 로그인 상태면 해당 페이지로 진입
        next();
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    // 로그인 페이지에 접근 시 이미 로그인 상태면 메인 페이지로 리다이렉트
    beforeEnter: (to, from, next) => {
      if (isLoggedIn()) {
        next({ name: 'home' });
      } else {
        next();
      }
    }
  },
  {
    path: '/device-dashboard',
    name: 'device-dashboard',
    component: DeviceDashboard,
  },
  {
    path: '/data-dashboard',
    name: 'data-dashboard',
    component: DataDashboard,
  },
  {
    path: '/account',
    name: 'account',
    component: AccountPage,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
