import axios from 'axios';
import store from '../store'; // Vuex 스토어 임포트

var BASE_URL_DEV = 'http://localhost:7001';
var BASE_URL_PROD = 'https://api.wooozza.com';

// BASE_URL은 개발모드인경우 BASE_URL_DEV, 프로덕션모드인경우 BASE_URL_PROD로 설정
var BASE_URL = process.env.NODE_ENV === 'development' ? BASE_URL_DEV : BASE_URL_PROD;
//BASE_URL = BASE_URL_PROD;

// Axios 기본 설정
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터 추가
axiosInstance.interceptors.request.use(config => {
    // Vuex 스토어에서 API_KEY 가져오기
    const apiKeyId = store.state.loginUser.API_KEY_ID;
    const apiKey = store.state.loginUser.API_KEY_SECRET;
    if (apiKeyId && apiKey) {
      // 요청 헤더에 API_KEY 추가
      config.headers['X-API-ID'] = `${apiKeyId}`;
      config.headers['X-API-KEY'] = `${apiKey}`;
    }
    store.commit('setIsLoading', true);
    return config;
  }, error => {
    // 요청 에러 처리
    store.commit('setIsLoading', false);
    return Promise.reject(error);
  }  
);

// 응답 인터셉터 추가
axiosInstance.interceptors.response.use(response => {
    store.commit('setIsLoading', false);
    return response;
  }, error => {
    store.commit('setIsLoading', false);
    return Promise.reject(error);
  });  

export default axiosInstance;